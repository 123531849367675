import React from 'react';
import './style.scss';

const Contact = () => {
    return (
      <>
    <div className='contact'>
        <div className="contact-wrapper">
            <div className='contact-text-container'>
                <h2 className='contact-title'>Contato</h2>
                <p className='contact-text'>Envie uma mensagem, crítica, feedback ou dúvida. Farei o possível para o responder o quanto antes. Caso preferir pode entrar em contato diretamente pelo Email e WhatsApp encontrados abaixo.</p>
                <p className='contact-email'>contato@brunomat.com</p>
                <p className='contact-phone'><a href='https://wa.link/jiqi4r' className='link'>+55 (47) 98851-9284</a></p>
            </div>

            {/* <div className='contact-form'>
                <label className='input-label'>Nome</label>
                <input type="text" name="" id="" placeholder='Nome' className="contact-input" />
                <label className='input-label'>Email</label>
                <input type="text" name="" id="" placeholder='Email' className="contact-input" />
                <label className='input-label'>Mensagem</label>
                <textarea placeholder='Mensagem...' name="" id="" cols="30" rows="30" className='contact-input textarea'></textarea>
                <button className='submit-button'>Enviar</button>
            </div> */}


            
        </div>
    </div>
    
    </>
  )
}

export default Contact