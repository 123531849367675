import React from 'react';
import './style.scss';
import { BsInstagram, BsBehance } from 'react-icons/bs';


const About = () => {
  return (
    <div className='about'>
        <div className="about-wrapper">
            <div className='about-image-container'>
              <img src="https://i.imgur.com/t6jFeYL.jpg" alt="Bruno Matsu" className='about-img' />
            </div>

            <div className="about-text-container">
                <h2 className='about-title'>Olá, Muito Prazer!</h2>
                <p className='about-text'>Me chamo Bruno Matsu e sou apaixonado por Design de marca há mais de 3 anos. Desde criança, me envolvi com diversas formas de expressão criativa, como escrita, desenho e programação. 
              <br/><br/>Hoje, meu objetivo é ajudar marcas a contar suas histórias de maneira única e impactante através de um design cuidadosamente projetado. Acredito que um design eficaz não apenas posiciona uma marca no mercado, mas também estabelece conexões duradouras com os consumidores.</p>
                <div className='about-buttons'>
                <a href="https://www.instagram.com/brunomatstudio/" className='link'><button className='about-button'><BsInstagram />Instagram</button></a>
                <a href="https://www.behance.net/brunomatsu" className='link'><button className='about-button'><BsBehance />Behance</button></a>
                </div>
           
            </div>


        </div>
    </div>
  )
}

export default About