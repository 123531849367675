import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/home/Home';
import Navbar from './components/navbar/Navbar';
import Caffe from './pages/projects/Caffe';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import DeliveryGo from './pages/projects/DeliveryGo';
import Hollos from './pages/projects/Hollos';
import Camelia from './pages/projects/Camelia';
import Ambiento from './pages/projects/Ambiento';
import DRXZ from './pages/projects/DRXZ';
import { AnimatePresence } from 'framer-motion';

function App() {

  const location = useLocation();

  return (
    <div className="App">
      <Navbar />
        <AnimatePresence mode=''>
        <Routes location={location} key={location.pathname}>
          <Route path='/' index element={<Home />} />
          <Route path='/caffe' element={<Caffe />} />
          <Route path='/deliverygo' element={<DeliveryGo />} />
          <Route path='/hollos' element={<Hollos />} />
          <Route path='/camelia' element={<Camelia />} />
          <Route path='/ambiento' element={<Ambiento />} />
          <Route path='/drxz' element={<DRXZ />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
        </AnimatePresence>
    </div>
  );
}

export default App;
