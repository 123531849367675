import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';


const Caffe = () => {

    window.scrollTo(0,0)
   
  return (
    	<div className='project'>
            <div className="project-wrapper">
                
                <div className='project-title-container'>
                    <h1 className='project-title'>Caffè</h1>
                    <p className='project-text'>  Caffè é uma marca de cafés que visa entregar ao consumidor um café refinado e artesanalmente tostado, obtendo um toque único e marcante.</p>
                </div>

                <div className="project-img">
                    <img src="https://i.imgur.com/1fr5kn8.png" alt="Caffè Logo" className='project-page-img' />
                </div>
                
                <div className="project-imgs-wrapper">
                    <div className="s-project-img">
                        <img src="https://i.imgur.com/hljPYpQ.png" alt="Caffé Package" className='project-page-img-s' />
                    </div>
                    
                    <div className="s-project-img">
                    <img src="https://i.imgur.com/cuJWkmL.jpg" alt="Caffé Frame" className='project-page-img-s' />
                    </div>
                </div>

                <div className='project-subtitle-container'>
                    <h1 className='project-subtitle'>O Principal Valor<br/>da Marca.</h1>
                    <p className='project-text'> O principal valor da marca é: oferecer um produto exclusivo e feito com carinho, para aprofundar o contato do cliente com o mundo da degustação de café.</p>
                </div>


                <div className="project-img">
                    <img src="https://i.imgur.com/HgmyCtc.jpg" alt="Caffè Logo" className='project-page-img' />
                </div>

                <div className="project-img">
                    <img src="https://i.imgur.com/zeM9pHC.png" alt="Caffè Logo" className='project-page-img' />
                </div>

                <div className="project-img">
                    <img src="https://i.imgur.com/uFbmVoF.png" alt="Caffè Logo" className='project-page-img' />
                </div>





                <div className='footer'>
        <div className='footer-wrapper'>
          <div className='footer-logo-div'>
            <img src="https://i.imgur.com/8knzcLr.png" alt="Bruno Matsu" className='footer-logo'/>
          </div>

          <div className='footer-links-div'>
            <div className='footer-links'>
              <h4 className='footer-link-title'>Páginas</h4>
              <p className='footer-link'><a href='/#projects' className='link'>Projetos</a></p>
              <p className='footer-link'><Link to='/about' className='link'>Sobre</Link></p>
              <p className='footer-link'><Link to='/contact' className='link'>Contato</Link></p>
            </div>
           
            <div className='footer-links'>
              <h4 className='footer-link-title'>Redes Sociais</h4>
              <p className='footer-link'><a href='https://www.instagram.com/brunomatstudio/' className='link'>Instagram</a></p>
              <p className='footer-link'><a href='https://www.behance.net/brunomatsu' className='link'>Behance</a></p>
              <p className='footer-link'><a href='https://www.linkedin.com/in/brunomatsu/' className='link'>LinkedIn</a></p>
            </div>

            
       
       
            <div className='footer-links'>
              <h4 className='footer-link-title'>Contato</h4>
              <p className='footer-link'>contato@brunomat.com</p>
              <p className='footer-link'><a href='https://wa.link/jiqi4r' className='link'>+55 (47) 9 8851-9284</a></p>
            </div>
          </div>
        </div>
      </div>
              
            </div>
        </div>
  )
}

export default Caffe