import React from 'react';
import { Link } from 'react-router-dom';


const DeliveryGo = () => {

    window.scrollTo(0,0)


  return (
    <div className='project'>
            <div className="project-wrapper">
                
                <div className='project-title-container'>
                    <h1 className='project-title'>Delivery Go</h1>
                    <p className='project-text'>Delivery Go é uma startup focada em oferecer serviços de delivery para pequenas empresas. O objetivo central deste projeto foi criar uma identidade sólida e moderna.</p>
                </div>

                <div className="project-img">
                    <img src="https://i.imgur.com/CPoFSM4.jpg" alt="Caffè Logo" className='project-page-img' />
                </div>
                
                <div className="project-imgs-wrapper">
                    <div className="s-project-img">
                        <img src="https://i.imgur.com/edI5zID.png" alt="Caffé Package" className='project-page-img-s mobile-width' />
                    </div>
                    
                    <div className="s-project-img">
                    <img src="https://i.imgur.com/q849ztv.png" alt="Caffé Frame" className='project-page-img-s' />
                    </div>
                </div>

                <div className="project-img">
                    <img src="https://i.imgur.com/M8yCYoB.jpg" alt="Caffè Logo" className='project-page-img' />
                </div>

                <div className="project-img">
                    <img src="https://i.imgur.com/N66T87G.jpg" alt="Caffè Logo" className='project-page-img ' />
                </div>

                <div className="project-img">
                    <img src="https://i.imgur.com/JxWYozN.jpg" alt="Caffè Logo" className='project-page-img full-height' />
                </div>

                <div className="project-img">
                    <img src="https://i.imgur.com/P2TnwEr.jpg" alt="Caffè Logo" className='project-page-img' />
                </div>





                <div className='footer'>
        <div className='footer-wrapper'>
          <div className='footer-logo-div'>
            <img src="https://i.imgur.com/8knzcLr.png" alt="Bruno Matsu" className='footer-logo'/>
          </div>

          <div className='footer-links-div'>
            <div className='footer-links'>
              <h4 className='footer-link-title'>Páginas</h4>
              <p className='footer-link'><a href='/#projects' className='link'>Projetos</a></p>
              <p className='footer-link'><Link to='/about' className='link'>Sobre</Link></p>
              <p className='footer-link'><Link to='/contact' className='link'>Contato</Link></p>
            </div>
           
            <div className='footer-links'>
              <h4 className='footer-link-title'>Redes Sociais</h4>
              <p className='footer-link'><a href='https://www.instagram.com/brunomatstudio/' className='link'>Instagram</a></p>
              <p className='footer-link'><a href='https://www.behance.net/brunomatsu' className='link'>Behance</a></p>
              <p className='footer-link'><a href='https://www.linkedin.com/in/brunomatsu/' className='link'>LinkedIn</a></p>
            </div>

            
       
       
            <div className='footer-links'>
              <h4 className='footer-link-title'>Contato</h4>
              <p className='footer-link'>contato@brunomat.com</p>
              <p className='footer-link'><a href='https://wa.link/jiqi4r' className='link'>+55 (47) 9 8851-9284</a></p>
            </div>
          </div>
        </div>
      </div>
              
            </div>
        </div>
  )
}

export default DeliveryGo