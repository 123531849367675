import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import { BiMenu } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import { easeInOut, motion } from 'framer-motion';

const Navbar = () => {

  const [openMenu,setOpenMenu] = useState(false)

  return (
    <>
    <div className='navbar'>
      <div className="navbar-wrapper">
        <Link to='/'>
          <div className='navbar-logo-container'>
            <img src="https://i.imgur.com/8knzcLr.png" alt="Bruno Matsu" className='navbar-logo'/>
          </div>
        </Link>

        <div className='navbar-links-container'>
            <ul className='navbar-links'>
              <a href='/#projects' className='link'>
                <li className="navbar-link">Projetos</li>
              </a>
              <Link to='/about' className='link'>
              <li className="navbar-link">Sobre</li>
              </Link>
              <Link to='/contact' className='link'>
                <li className="navbar-link">Contato</li>
              </Link>
            </ul>
        </div>
      </div>

      <BiMenu className='menu-button' onClick={(e) => setOpenMenu(true)}/>



    </div>

    {openMenu == true ? 
     <motion.div className='menu' animate={{ y: [-1000, 0]}} transition={{duration: .5, ease: [.15,1.01,.99,1.01]}}>
     <motion.div className='close-button-anim' animate={{ opacity: [0, 1]} } transition={{duration: 1, ease: [.15,1.01,.99,1.01], delay: .45 }}>
     <AiOutlineClose className='close-button' onClick={(e) => setOpenMenu(false)}/>
     </motion.div>

     <motion.p className='menu-link' animate={{ opacity: [0, 1]} } transition={{duration: 1, ease: easeInOut, delay: .40 }} onClick={(e) => setOpenMenu(false)}><a href='/#projects' className='link'>Projetos</a></motion.p>
     <motion.p className='menu-link' animate={{ opacity: [0, 1]} } transition={{duration: 1, ease: easeInOut, delay: .60 }} onClick={(e) => setOpenMenu(false)}><Link to='/about' className='link'>Sobre</Link></motion.p>
     <motion.p className='menu-link' animate={{ opacity: [0, 1]} } transition={{duration: 1, ease: easeInOut, delay: .7 }} onClick={(e) => setOpenMenu(false)}><Link to='/contact' className='link'>Contato</Link></motion.p>
  </motion.div>  
   : null}

 
    </>
  )
}

export default Navbar